import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
// import Loader from '../components/loader/Loader';

const Main = lazy(() => import('../views/Main'));

// const PoliticaDeCookies = lazy(() =>
//   import('../views/pages/cookies/PoliticaDeCookies')
// );
const PoliticaDePrivacidad = lazy(() =>
  import('../views/pages/privacity/PoliticaDePrivacidad')
);
const TerminosYCondiciones = lazy(() =>
  import('../views/pages/terms/TerminosYCondiciones')
);

export const Router = () => {
  return (
    <BrowserRouter>
      {/* <ScrollToTop /> */}

      {/* <Loader /> */}
      <Suspense fallback={<></>}>
        <Switch>
          <Route exact path="/" render={(props) => <Main {...props} />} />

          {/* <Route
            path="/politica-de-cookies"
            exact
            render={(props) => <PoliticaDeCookies {...props} />}
          /> */}
          <Route
            path="/privacy-policy"
            exact
            render={(props) => <PoliticaDePrivacidad {...props} />}
          />
          <Route
            path="/terms-and-conditions"
            exact
            render={(props) => <TerminosYCondiciones {...props} />}
          />
          <Redirect to="/" />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
